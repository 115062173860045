import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Home from "../LandingPage/Home";
import LoginForm from "../Login/LoginForm";
import UploadImage from "../Login/UploadImage";
import Product from "../ProductTour/Product";
import TestLibrary from "../TestLibrary/TestLibrary";
import Pricing from "../Pricing/Pricing";
import Congratulation from "../Congratulation/Congratulation";
import CreateAssessments from "../CreateAssessments/CreateAssessments";
import AssessmentsDashboard from "../Dashboard/AssessmentsDashboard";
import Assessment from "../Dashboard/Assessment/Assessment";
import CandidatesDashboard from "../Dashboard/CandidatesDashboard";
import CandidateResult from "../Candidates/CandidateResult";
import NoAssessment from "../Dashboard/NoAssessment/NoAssessment";
import NoCandidate from "../Dashboard/NoCandidate/NoCandidate";
import Settings from "../Settings/Settings";
import CandidateVerification from "../Candidates/CandidateVerification";
import CandidateGetStarted from "../Candidates/CandidateGetStarted";
import AssessmentOverview from "../Candidates/AssessmentOverview";
import UploadFiles from "../Candidates/UploadFiles";
import AttachLink from "../Candidates/AttachLink/AttachLink";
import PrivateRoutes from "./Protected";
import ThankyouNote from "../Candidates/ThankyouNote";
import FirstAssessment from "../Candidates/FirstAssessment/FirstAssessment";
import WaitList from "../WaitList/WaitList";
import SignupScreen from "../SignUp/SignupScreen";
import AddAssessment from "../Dashboard/AddAssessment/AddAssessment";
import SelectTests from "../Dashboard/SelectTests/SelectTests";
import CustomQuestions from "../Dashboard/CustomQuestions/CustomQuestions";
import ReviewAssessment from "../Dashboard/ReviewAssessment/ReviewAssessment";
import InviteCandidate from "../Dashboard/InviteCandidate/InviteCandidate";
import LoginScreen from "../Login/LoginScreen/LoginScreen";
import ForgetPassword from "../Forget/ForgetPassword";
import VerifyEmail from "../Forget/VerifyEmail";
import ResetPassword from "../Forget/ResetPassword";
import ConfirmVerify from "../Forget/ConfirmVerify";
import { useSelector } from "react-redux";
import AssessProjection from "./AssessProjection";
import useToken from "../../hooks/useToken";
import ProductTour from "../product-tour";
import Notifications from "../Settings/Notifications";
import MyProfile from "../Settings/MyProfile";

function AppRouter() {
  const authToken = useSelector((state) => state.auth.token);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;

  // useEffect(() => {
  //   if (
  //     pathname === "/loginScreen" ||
  //     pathname === "/signup" ||
  //     pathname === "/" ||
  //     pathname.startsWith("/verify-email") ||
  //     pathname.startsWith("/candidate-verification") ||
  //     pathname.startsWith("/CandidateGetStarted") ||
  //     pathname.startsWith("/AssessmentOverview") ||
  //     (pathname.startsWith("/FirstAssessment") &&
  //       localStorage.getItem("candidateInfo"))
  //   ) {
  //     navigate(pathname);
  //   } else {
  //     setTimeout(() => {
  //       if (!token) {
  //         console.log(token);
  //         navigate("/loginScreen");
  //       }
  //     }, 3000);
  //   }
  // }, [token, pathname, navigate]);

  return (
    <Routes>
      {/* <Route path="/product" element={<Product />} />
      <Route path="/test-library" element={<TestLibrary />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/create-assessments/:id?" element={<CreateAssessments />} />
      <Route path="/add-assessment" element={<AddAssessment />} />
      <Route path="/add-assessment/:id" element={<AddAssessment />} />
      <Route path="/select-test/:id" element={<SelectTests />} />
      <Route path="/custom-questions/:id" element={<CustomQuestions />} />
      <Route path="/review/:id" element={<ReviewAssessment />} />
      <Route path="/invite-candidate/:id" element={<InviteCandidate />} />

      <Route path="/AssessmentsDashboard" element={<AssessmentsDashboard />} />
      <Route path="/Assessment/:id" element={<Assessment />} />
      <Route path="/CandidatesDashboard" element={<CandidatesDashboard />} /> */}

      <Route path="/" element={<Home />} />
      <Route
        path="/candidate-verification/:token"
        element={
          <AssessProjection>
            <CandidateVerification />
          </AssessProjection>
        }
      />
      <Route path="/congratulation" element={<Congratulation />} />

      <Route
        path="/CandidateGetStarted/:token"
        element={
          <AssessProjection>
            <CandidateGetStarted />
          </AssessProjection>
        }
      />
      <Route
        path="/AssessmentOverview/:token"
        element={
          <AssessProjection>
            <AssessmentOverview />
          </AssessProjection>
        }
      />

      <Route
        path="/FirstAssessment/:token/:index?"
        element={<FirstAssessment />}
        replace
      />

      <Route path="/ThankyouNote/:token" element={<ThankyouNote />} />
      <Route path="/waitList" element={<WaitList />} />

      {/* <Route path="/uploadFiles" element={<UploadFiles />} />
      <Route path="/AttachLink" element={<AttachLink />} />
      <Route
        path="/CandidateResult/:id/:assessmentID"
        element={<CandidateResult />}
      />
      <Route path="/NoAssessment" element={<NoAssessment />} />
      <Route path="/NoCandidate" element={<NoCandidate />} />
      <Route path="/Settings/:activeMenu" element={<Settings />} />
      <Route path="/notifications:activeMenu" element={<Notifications />} />
      <Route path="/myProfile:activeMenu" element={<MyProfile />} />
      <Route path="/signup" element={<SignupScreen />} />
      <Route path="/loginScreen" element={<LoginScreen />} />
      <Route path="/LoginForm" element={<LoginForm />} />
      <Route path="/UploadImage" element={<UploadImage />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/verify-email/:token/:id" element={<VerifyEmail />} />

      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/confirm-verify/:token" element={<ConfirmVerify />} />
      <Route path="/product-tour" element={<ProductTour />} /> */}
    </Routes>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}

export default Router;
