import React from "react";
import "./styles.scss";
import { Vector } from "../../../assets/icons";

const Card = ({ image }) => {
  return (
    <div className="card">
      <img className="staffImg" src={image} alt="" />
      <div className="name-div">
        <div className="info">
          <h5 className="name">Chris Evens</h5>
          <h6 className="position">Project Trainee.</h6>
        </div>
        <div className="img-div">
          <img className="vector" src={Vector} alt="" width="12" height="13" />
          <div className="text">
            <p>89.3%</p>
          </div>
          <div className="mobText">
            <p>Hired</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
