import React, { useState } from "react";
import "./styles.scss";
import Star from "../Star/Star";

const StarRating = ({ setRating }) => {
  const [starIndex, setStarIndex] = useState(-1);

  const handleClick = (index) => {
    setStarIndex(index);
    setRating(index);
  };

  return (
    <div className="starsDiv">
      {[...Array(5)].map((item, index) => {
        return (
          <Star fill={index > starIndex} onClick={() => handleClick(index)} />
        );
      })}
    </div>
  );
};

export default StarRating;
