import axios from "axios";
import webURL from "../screens/Utils/Api";

export const Test = {
  UpdateQuestion: async (
    token,
    id,
    editTitle,
    editExistingQuestion,
    mcqsOptions,
    editMcqAnswer
  ) => {
    return axios.put(
      `${webURL}/question/update/${id}`,
      {
        title: editTitle,
        type: "MCQ",
        question: editExistingQuestion,
        mcq_options: mcqsOptions,
        mcq_answers: editMcqAnswer,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  RemoveTestFromAssessment: async (token, id, testId) => {
    return axios.put(
      `${webURL}/assessment/remove/${id}`,
      {
        testId: testId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  RemoveQuestionFromTest: async (token, questionID, testID) => {
    return axios.put(
      `${webURL}/useTest/remove/${testID}`,
      {
        questionId: questionID,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  AddTestsToAssessment: async (token, id, onlyID) => {
    let testIds = [];
    onlyID.map((item) => testIds.push(item[item.test_id ? "test_id" : "_id"]));
    return axios.put(
      `${webURL}/assessment/add/${id}`,
      {
        testData: testIds,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  CreateWithAi: async (prompt) => {
    try {
      const response = await axios.post(`${webURL}/chat`, { prompt });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
