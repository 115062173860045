import React, { useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../../components/Common/Button";
import StarRating from "../../../components/Common/StarRating/StarRating";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { serviceErrorHandler } from "../../../config";
import Spinner from "../../../components/Common/Spinner";
import { useParams } from "react-router-dom";
import { Candidates } from "../../../service/Candidate";

const ThankyouNote = () => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [aftermath, setAfterMath] = useState(false);
  const { token } = useParams();

  async function takeReviews() {
    setSpinner(true);
    Candidates.AddReview(token, rating, comment)
      .then((resp) => {
        setSpinner(false);
        setAfterMath(true);
      })
      .catch((error) => {
        toast.error(serviceErrorHandler(error));
        setAfterMath(true);
        setSpinner(false);
      });
  }

  return (
    <div className="ThankyouDiv">
      <div className="ThankyouNote ">
        {!aftermath ? (
          <>
            <div className="thankDesc">
              <h1>Thank you...</h1>
              <p>
                You’ve finished your assessment. How was <br />
                your experience, tell us through ratings...
              </p>
            </div>
            {/* <div className="note">
              <p>
                <span>Note :</span> You won’t have to wait long for your
                assessment test
                <br />
                results, which can be previewed online 13 calendar days <br />
                after the test for paper-based and 3 - 5 calendar days.
              </p>
            </div> */}
            <StarRating setRating={setRating} />
            <div className="thoughts">
              <p>Do you have any thoughts, would you like to share?</p>
            </div>
            <div className="textArea">
              <textarea
                placeholder="Please Share..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div className="btnSubmit">
              <Button
                disable={spinner}
                onClick={() => {
                  takeReviews();
                  localStorage.removeItem("Assessment");
                }}
                large
              >
                {spinner ? <Spinner /> : "Submit"}
              </Button>
            </div>
          </>
        ) : (
          <div className="closeWindow">
            <h1>Thank you...</h1>
            <p>You may now close this window</p>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ThankyouNote;
