import React, { useState } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import Button from "../Button";
import InputField from "../InputField/InputField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Spinner";
import { facebook, instagram, twitter, youtube } from "../../../assets/icons";
import { Waitlist } from "../../../service/Waitlist";

const HomePageFooter = () => {
  const notify = () => {
    toast("Thankyou for subscribing to news letter");
  };
  const notifyTwo = () => {
    toast("Please enter valid email");
  };

  const [email, setEmail] = useState("");
  const [spinner, setSpinner] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const clearEmail = () => {
    setEmail("");
    setEmail(null);
  };

  const newsletter = () => {
    setSpinner(true);
    Waitlist.Newsletter(email)
      .then((resp) => {
        setSpinner(false);
        clearEmail();
        if (resp.request.status === 200) {
          notify();
        }
      })
      .catch((err) => {
        setSpinner(false);
        notifyTwo();
        console.warn(err);
      });
  };

  return (
    <>
      <div className="homePageFooter">
        <div className="footerItems">
          <div className="footer-logo">
            <Link
              to="/"
              onClick={() => window.scrollTo(0, 0)}
              className="footerItem"
            >
              <img src="./images/company-logo.png" alt="" className="company" />
              <img
                src="./images/company-text.png"
                alt=""
                className="companyText"
              />
            </Link>
          </div>
          <div className="social-media">
            <a
              href="https://www.facebook.com/thehexatown/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={facebook} alt="" />
            </a>
            <a
              href="https://www.instagram.com/thehexatown/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={instagram} alt="" />
            </a>

            <a
              href="https://www.twitter.com/thehexatown/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={twitter} alt="" />
            </a>
            <a
              href="https://www.youtube.com/@thehexatown"
              rel="noreferrer"
              target="_blank"
            >
              <img src={youtube} alt="" />
            </a>
          </div>
        </div>
        <div className="footerSeparator"></div>
        <div className="buttonDiv">
          <h6>Sign up to our newsletter</h6>
          <div className="text">
            <p>Keep up to date with the latest tech hiring </p>
            <p>news and product updates</p>
          </div>
          <div className="Email">
            <InputField
              dull
              onChange={handleChange}
              id="email"
              name="email"
              type="email"
            >
              Email Address*
            </InputField>
            <div>
              <Button onClick={newsletter} disable={spinner} largeLight>
                {spinner ? (
                  <Spinner />
                ) : (
                  <p className="submitBtnText">SUBMIT</p>
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="MobMedia">
          <a
            href="https://www.facebook.com/thehexatown/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={facebook} alt="" width={10} height={18} />
          </a>
          <a
            href="https://www.instagram.com/thehexatown/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={instagram} alt="" width={16} height={16} />
          </a>
          <a
            href="https://www.twitter.com/thehexatown/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={twitter} alt="" width={17} height={14} />
          </a>
          <a
            href="https://www.youtube.com/@thehexatown"
            rel="noreferrer"
            target="_blank"
          >
            <img src={youtube} alt="" width={17} height={13} />
          </a>
        </div>
      </div>
      <div className="copyrights">
        <h5>
          <span>Copyrights &amp; Developed by</span> TheHexatown @ 2022
        </h5>
      </div>
    </>
  );
};
export default HomePageFooter;
