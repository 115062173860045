import React from "react";
import "./styles.scss";

const Spinner = ({ forGoogle }) => {
  return (
    <div className={`Spinner ${forGoogle === true ? "forGoogle" : ""}`}></div>
  );
};

export default Spinner;
