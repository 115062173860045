import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
const googleAuth = process.env.REACT_APP_GOOGLE_AUTH || " ";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={googleAuth}>
    <App />
  </GoogleOAuthProvider>
);
