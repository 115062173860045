import axios from "axios";
import webURL from "../screens/Utils/Api";

export const Waitlist = {
  Presale: async (values) => {
    return axios.post(`${webURL}/presale`, values);
  },
  Newsletter: async (email) => {
    return axios.post(`${webURL}/newsletter/signup`, {
      email: email,
    });
  },
};
