import Navbar from "../../components/Common/Navbar";
import Header from "../../components/Home/Header";
import Talent from "../../components/Home/Talent";
import Test from "../../components/Home/Test";

import BuildTeam from "../../components/Home/BuildTeam";
import Recruitment from "../../components/Home/Recruitment";
import Quality from "../../components/Home/Quality";
import SayAbout from "../../components/Home/SayAbout";
import "../Utils/Helper.scss";
import HomePageFooter from "../../components/Common/HomePageFooter";

const Home = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Navbar dark />
        <Header />
        <Talent />
        <Test />
        <BuildTeam />
        <Recruitment />
        <Quality />
        <SayAbout />
        <HomePageFooter />
      </div>
    </>
  );
};

export default Home;
