import React from "react";
import "./styles.scss";
import { frustated } from "../../../assets/images";
import { hiringCircle } from "../../../assets/icons/index";

const CompanyInfo = () => {
  return (
    <div className="companyinfo">
      <div className="frustrated">
        <img className="shouting" src={frustated} alt="" />
        <div className="frustText">
          <img className="hiringCircle" src={hiringCircle} alt="" />
          <h6>
            Frustrated with your
            <br /> hiring process? You’re
            <br /> just one step away...
          </h6>
        </div>
      </div>
    </div>
  );
};
export default CompanyInfo;
