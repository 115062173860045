import React from "react";
import "./styles.scss";

const Button = ({
  children,
  outline,
  Darkoutline,
  dark,
  large,
  largeOutline,
  largeLight,
  blue,
  smallBorder,
  smallDull,
  darkBorder,
  Delete,
  homeLargeLight,
  leftBorder,
  rightBorder,
  icon,
  disable,
  size = "medium",
  ...props
}) => {
  const classNames = [
    size,
    outline && "outline",
    Darkoutline && "Darkoutline",
    dark && "dark",
    large && "large",
    largeOutline && "largeOutline",
    largeLight && "largeLight",
    blue && "blue",
    smallBorder && "smallBorder",
    smallDull && "smallDull",
    leftBorder === "leftBorder" && "leftBorder",
    leftBorder === "basicLeft" && "basicLeft",
    rightBorder === "rightBorder" && "rightBorder",
    rightBorder === "basicRight" && "basicRight",
    darkBorder && "darkBorder",
    Delete && "Delete",
    homeLargeLight && "homeLargeLight",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <button {...props} className={classNames} disabled={disable}>
      {children}
      {icon && <img src={icon} alt="" />}
    </button>
  );
};

export default Button;
