/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import Button from "../../Common/Button";
import "./styles.scss";

const SayAbout = () => {
  return (
    <div className="earlyAccessWrapper">
      <div className="earlyAccessContent">
        <p className="description">
          At early access, we’ve put a lot of work in creating valuable
          connections.
        </p>
        <div className="earlyAccessBtn">
          <Button dark>
            <p className="signUpBtnText">Get early access</p>
          </Button>
        </div>
      </div>
      <img
        src="./images/early-access.png"
        alt="Early access image"
        className="earlyAccessImage"
      />
    </div>
  );
};
export default SayAbout;
