import React, { useEffect, useState } from "react";

import "./styles.scss";
import { timer, totalTests } from "../../../assets/icons/index";
import IncludedTestTable from "../../../components/Common/IncludedTestTable";
import Button from "../../../components/Common/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../../components/Common/Spinner";
import { serviceErrorHandler } from "../../../config";
import { toast, ToastContainer } from "react-toastify";
import useToken from "../../../hooks/useToken";
import { Assessment } from "../../../service/Assessment";
const AssessmentOverview = () => {
  const [spinner, setSpinner] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [testLength, setTestLength] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  const navigate = useNavigate();
  const { token } = useParams();

  // useEffect(() => {
  //   let time = 0;
  //   for (let i = 0; i < testData.length; i++) {
  //     time = time + testData[i]?.time;
  //     setTotalTime(time);
  //   }
  // }, [testData]);

  const bearerToken = useToken();
  const ID = useSelector((state) => state.auth.ID);

  useEffect(() => {
    if (token) {
      getTestsByAssessmentID();
      setSpinner(true);
    }
  }, [token]);

  async function getTestsByAssessmentID() {
    Assessment.AssessmentById(ID, token)
      .then(({ data }) => {
        setTotalTime(
          data?.useTest
            .map((value) => value?.time)
            .reduce((acc, currentValue) => {
              return acc + currentValue;
            }, 0)
        );
        setSpinner(false);
        setTableData(data);
        setTestLength(data.useTest.length);
      })
      .catch((error) => {
        console.warn(error);
        setSpinner(false);
        toast.error(serviceErrorHandler(error));
      });
  }

  const handleStartAssessment = () => {
    localStorage.setItem("Assessment", "1");
    navigate(`/FirstAssessment/${token}`);
  };

  return (
    <div className="AssessmentOverview">
      <div className="container">
        <div className="overviewDiv">
          <h5>Get a quick review about your assessment</h5>

          <div className="assessmentcard">
            {spinner ? (
              <Spinner />
            ) : (
              <>
                <div className="assessmentName">
                  <div className="nameDiv">
                    <div>
                      <p>Assessment Name</p>
                      <h3>{tableData?.name}</h3>
                    </div>
                    <div className="testAndTime">
                      <div className="testsDiv">
                        <img src={totalTests} alt="" />
                        <p>{testLength}</p>
                      </div>
                      <div className="timerDiv">
                        <img src={timer} alt="" />
                        <p>{totalTime} min</p>
                      </div>
                    </div>
                  </div>
                </div>
                <IncludedTestTable tableData={tableData?.useTest} />
              </>
            )}
          </div>
          <div className="btnStart">
            <Button
              large
              onClick={() => {
                handleStartAssessment();
              }}
            >
              Start&nbsp;Assessment
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AssessmentOverview;
