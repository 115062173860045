import React from "react";
import "./styles.scss";
import Button from "../../Common/Button";
import { useNavigate } from "react-router-dom";
import { table, thinkingWomen } from "../../../assets/images";

const BuildTeam = () => {
  const navigate = useNavigate();

  const componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="buildTeam">
      <div className="mainDiv">
        <div className="mainDiv-heading">
          <h2>
            Build your team based on evidence,
            <br /> not assumption...
          </h2>
        </div>
        <div className="mainDiv-heading-container">
          <div className="images">
            <img
              className="table"
              src={table}
              alt=""
              width={558}
              height={310}
            />
            <img
              className="thinking-woman"
              src={thinkingWomen}
              alt=""
              width={522}
              height={596}
            />
          </div>
          <div className="btn-div">
            <h5 className="build-team-p1">
              Predict real-world job performance using our library of 211
              <br />
              scientifically validated tests.
            </h5>

            <h5 className="build-team-p1">
              Test candidates for job-specific skills like coding or digital
              <br />
              marketing, as well as more general skills like critical thinking.
            </h5>

            <h5 className="build-team-p1">
              Our unique personality and value tests allow you to get to know
              your
              <br />
              applicants as real people – not just pieces of paper.
            </h5>
            <div className="btnLearn">
              <Button
                // onClick={() => {
                //   componentDidMount();
                //   navigate(`/product`);
                // }}
                dark
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BuildTeam;
