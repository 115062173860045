import React from "react";
import "./styles.scss";

const TopTalent = ({ color, image }) => {
  return (
    <div className="topTalentWrapper">
      <div style={{ backgroundColor: color }} className="topTalent">
        <h3>
          Top talent is priceless.
          <br />
          we make finding it affordable.
        </h3>

        <h6>
          Our screening tests identify the best candidates and. make your hiring
          decisions faster, easier, and bias-free.
        </h6>
        <div className="tabText">
          <p>
            Our screening tests identify the best
            <br /> candidates and make your hiring decisions
            <br /> faster, easier, and bias-free.
          </p>
        </div>
        <div className="mobText">
          <p>
            Our screening tests identify the best candidates
            <br /> and make your hiring decisions faster, easier,
            <br /> and bias-free.
          </p>
        </div>
      </div>
      <img src={image} alt="" className="numImage" />
    </div>
  );
};
export default TopTalent;
