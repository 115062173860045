import React from "react";
import "./styles.scss";
import { Fragment } from "react";

const DropdownSelect = ({ dropdownOptions, setSelected, error }) => {
  const options = dropdownOptions;
  return (
    <div className="DropdownSelect">
      <select
        name="profession"
        id="profession"
        className={error ? "error" : ""}
        onChange={(e) => setSelected(e.target.value)}
      >
        <option hidden value disabled></option>
        {options.map((option, index) => (
          <Fragment key={index}>
            <option key={index} className="options">
              {option}
            </option>
          </Fragment>
        ))}
      </select>
      {error && <p>Required</p>}
    </div>
  );
};

export default DropdownSelect;
