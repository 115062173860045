import React from "react";
import "./styles.scss";

const Logos = ({ image, width, height, children }) => {
  return (
    <div className="logos">
      {image && (
        <>
          <div className="tabSize">
            <img src={image} alt="" width={width} height={height} />
          </div>
          <div className="mobSize">
            <img src={image} alt="" width={64} height={57} />
          </div>
        </>
      )}
      <h6>{children}</h6>
    </div>
  );
};

export default Logos;
