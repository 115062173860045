import axios from "axios";
import webURL from "../screens/Utils/Api";

export const Employee = {
  AddEmployee: async (token, values) => {
    return axios.post(`${webURL}/employee`, values, {
      headers: { Authorization: ` Bearer ${token}` },
    });
  },
  OrganizationOfEmployee: async (token) => {
    return axios.get(`${webURL}/oragination-of-employees`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  editEmployeeRole: async (token, values, id) => {
    return axios.put(`${webURL}/employee/update/${id}`, values, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  deleteEmployeeOfOrganization: async (token, id) => {
    return axios.delete(`${webURL}/employee/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  GetEmployeeById: async (token, id) => {
    return axios.get(`${webURL}/employee/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  ChangeEmployeeStatus: async (authToken, id, values) => {
    return axios.put(`${webURL}/employee-verify/${id}`, values, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  },
};
