import React from "react";
import "./styles.scss";
import DataTable from "react-data-table-component";

const TestTable = ({ tableData }) => {
  const includedTestColumns = [
    {
      name: "INCLUDED TEST",
      selector: (data) => data.name,
    },
    {
      name: "Type",
      selector: (data) => data.type,
    },
    {
      name: "Time",
      selector: (data) => <p> {data.time} min</p>,
    },
  ];

  return <DataTable data={tableData} columns={includedTestColumns} />;
};

export default TestTable;
