import axios from "axios";
import webURL from "../screens/Utils/Api";

export const Change = {
  ForgetPassword: async (values) => {
    return axios.post(`${webURL}/forgot-password`, values);
  },
  ResetPassword: async (token, values) => {
    return axios.post(`${webURL}/reset-password/${token}`, values);
  },
  ChangeRole: async (token, values) => {
    return axios.put(`${webURL}/update-profile`, values, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  UpdateOrganization: async (token, values) => {
    return axios.put(`${webURL}/organization-of-employee/update`, values, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
