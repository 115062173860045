import React from "react";
import "./styles.scss";

const AssessmentBar = ({number,name}) => {
  return (
    <div className="assessmentNumber">
      <h1>
        <span>Assessment {number}</span> : {name}
      </h1>
    </div>
  );
};

export default AssessmentBar;
