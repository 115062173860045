import axios from "axios";
import webURL from "../screens/Utils/Api";

export const Assessment = {
  CloneQuestion: async (token, id, testID) => {
    return axios.post(
      `${webURL}/question/clone/${testID}`,
      {
        questionId: id,
      },
      {
        headers: { Authorization: ` Bearer ${token}` },
      }
    );
  },
  AddCustomQuestion: async (token, id, values) => {
    return axios.post(`${webURL}/question/useTest/${id}`, values, {
      headers: { Authorization: ` Bearer ${token}` },
    });
  },
  AddAssessment: async (values, token) => {
    return axios.post(`${webURL}/assessment`, values, {
      headers: { Authorization: ` Bearer ${token}` },
    });
  },
  UpdateAssessment: async (token, values, id) => {
    return axios.put(`${webURL}/assessment/update/${id}`, values, {
      headers: { Authorization: ` Bearer ${token}` },
    });
  },
  AddAnswers: async (token, test, answers, bearerToken) => {
    // console.log(
    //   { token: token, answe: "tis is the toekn" },
    //   { testId: testId, answe: "tis is the testId" },
    //   { answers: answers, answe: "tis is the answers" },
    //   "this is request"
    // );
    return axios.post(
      `${webURL}/answers/${token}`,
      {
        test: test,
        answers: answers,
      },
      {
        headers: { Authorization: ` Bearer ${bearerToken}` },
      }
    );
  },
  AssessmentById: async (id, token) => {
    return axios
      .get(`${webURL}/assessment/${id}`, {
        headers: { Authorization: ` Bearer ${token}` },
      })
      .then(({ data }) => {
        return data;
      });
  },
  StartAssessment: async (token) => {
    return axios.get(
      `${webURL}/assessment/hexa-hing/start-assessment/${token}`,
      {
        headers: { Authorization: ` Bearer ${token}` },
      }
    );
  },

  AssessmentByOrganization: async (page, limit, token, searchAssesment) => {
    return axios.get(
      `${webURL}/organizationAssessments?page=${page}&limit=${limit}&name=${searchAssesment}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  OrganizationAssessment: async (token) => {
    return axios.get(`${webURL}/organizationAssessments?page`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  AllTest: async (token, searchText = "") => {
    return axios.get(
      `${webURL}/test/list?page=0&limit=4&name=${searchText}&level=&type=`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  deleteAssessmentByID: async (token, id) => {
    return axios.delete(`${webURL}/assessment/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
