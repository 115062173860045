import React, { useState, useEffect } from "react";
import AssessmentBar from "../../../components/Create&Assessment/AssessmentBar/AssessmentBar";
import "./styles.scss";
import Button from "../../../components/Common/Button";
import { hiringHash } from "../../../assets/icons";
import { timer } from "../../../assets/icons/index";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../components/Common/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { serviceErrorHandler } from "../../../config";
import useToken from "../../../hooks/useToken";
import { Assessment } from "../../../service/Assessment";
import ReactHtmlParser from "react-html-parser";

const FirstAssessment = () => {
  const { token, index } = useParams();
  // set question when start aessment
  const [tests, setTests] = useState([]);

  const candidateInfo = localStorage.getItem("candidateInfo");
  // set opction when click on opction
  const [selectedOption, setSelectedOption] = useState(0);
  // set next question logic
  const [testIndex, setTestIndex] = useState(0);
  //set question index
  const [questionIndex, setQuestionIndex] = useState(0);
  // diplay spiner
  const [spinner, setSpinner] = useState(false);
  // set answer
  const [answers, setAnswers] = useState([]);

  // progress
  const [progressBarLength, setprogressBarLength] = useState(100);
  const [totalTime, settotalTime] = useState(0);

  // render thanks
  const [renderThanks] = useState(false);

  const intialTimer = localStorage.getItem("timer" + token)
    ? JSON.parse(localStorage.getItem("timer" + token))
    : null;

  // question timer
  const [Timer, setTimer] = useState(intialTimer);

  // set progress bar width
  useEffect(() => {
    if (Timer && Timer.timeLeft && totalTime) {
      setprogressBarLength((Timer.timeLeft / (totalTime * 60)) * 100);
    }
  }, [Timer, totalTime]);

  // const location = useLocation();
  const navigate = useNavigate();
  // test section token

  // authentication token
  const bearerToken = useToken();

  const selectOption = () => {
    toast("Please select correct option");
  };

  const handleAnswers = () => {
    let tempAnswers = { question: "", answer: "" };
    tempAnswers.answer = selectedOption;
    tempAnswers.question = tests[testIndex]?.question[questionIndex]?._id;
    let tempAnswersArray = [...answers, tempAnswers];
    setAnswers(tempAnswersArray);
    return tempAnswersArray;
  };
  async function submitAnswers(testId, selectedAnswers) {
    setSpinner(true);
    setAnswers([]);
    Assessment.AddAnswers(token, testId, selectedAnswers, bearerToken)
      .then((resp) => {
        setSpinner(false);
        if (testIndex > tests.length - 1) {
          localStorage.setItem("Assessment", "1");
          navigate(`/ThankyouNote/${token}`);
        }
      })
      .catch((error) => {
        setSpinner(false);
        setAnswers([]);
        if (error.response.status === 409 && testIndex > tests.length - 1) {
          localStorage.setItem("Assessment", "1");
          navigate(`/ThankyouNote/${token}`);
        }
        toast.error(serviceErrorHandler(error));
        console.warn(error);
      });
  }

  const handleIndex = () => {
    if (selectedOption !== 0) {
      let answerData = handleAnswers();
      if (questionIndex < tests[testIndex]?.question.length - 1) {
        setQuestionIndex((questionIndex) => questionIndex + 1);
        setSelectedOption(0);
        navigate(`/FirstAssessment/${token}/${questionIndex + 1}`);
      } else {
        submitAnswers(tests[testIndex]?._id, answerData);

        if (testIndex !== tests.length - 1) {
          setTestIndex(testIndex + 1);
          setQuestionIndex(0);
          setSelectedOption(0);
        } else {
          localStorage.setItem("Assessment", "1");
          navigate(`/ThankyouNote/${token}`);
        }
      }
    } else {
      selectOption();
    }
  };

  async function startAssessment() {
    setSpinner(true);
    // Set initial timer to 30 minutes

    try {
      const res = await Assessment.StartAssessment(token);
      setSpinner(false);
      setTests(res?.data.assessment.useTest);

      const time = res?.data.assessment.time;
      settotalTime(time);
      const timeLeft = res?.data.assessmentTime;
      const assessmentTime = {
        minutes: timeLeft,
        seconds: 0,
        timeLeft: timeLeft * 60,
      };
      if (!Timer) {
        setTimer(assessmentTime);
      }
    } catch (error) {
      console.warn(error);
      setSpinner(false);
      toast.error(serviceErrorHandler(error));

      if (
        error.response.data.message === "Assessment time is over" ||
        "Your answers have already been submitted "
      ) {
        localStorage.setItem("Assessment", "1");
        navigate(`/ThankyouNote/${token}`);
      }
    }
  }

  let setIndexOfChoices = (Id) => {
    setSelectedOption(Id);
  };

  useEffect(() => {
    if (token) {
      startAssessment();
      setSpinner(true);
    }
  }, [token]);

  useEffect(() => {
    if (index) setQuestionIndex(Number(index));
  }, []);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (!prevTimer) return;
        if (prevTimer.minutes === 0 && prevTimer.seconds === 0) {
          // Timer has reached 0
          localStorage.setItem("Assessment", "1");
          clearInterval(timerInterval); // Stop the interval
          // Add your navigation logic here
          return prevTimer;
        }
        const newTimer = {
          ...prevTimer,
          seconds: prevTimer.seconds > 0 ? prevTimer.seconds - 1 : 59,
          minutes:
            prevTimer.seconds === 0 ? prevTimer.minutes - 1 : prevTimer.minutes,
          timeLeft: prevTimer.timeLeft - 1,
        };
        // Save the updated timer state to localStorage
        localStorage.setItem("timer" + token, JSON.stringify(newTimer));
        return newTimer;
      });
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  return (
    <div className="AssessmentWrapper">
      <AssessmentBar number={testIndex + 1} name={tests[testIndex]?.name} />
      <div className="FirstAssessment" id="thisAssessmentScreen">
        <div className="container">
          <div className="logoContainer">
            <img src={hiringHash} alt="" width={70} height={53} />
            <p>(beta)</p>
          </div>

          {spinner ? (
            <Spinner />
          ) : renderThanks === true ? (
            <Spinner />
          ) : (
            <>
              {!!Timer && (
                <div className="timerDiv">
                  <img src={timer} alt="" />
                  <div>
                    <span>{Timer.minutes}</span>
                    <span>:</span>
                    <span>{Timer.seconds}</span>
                  </div>
                </div>
              )}
              <progress max={100} value={progressBarLength}></progress>
              <div className="testContainer">
                <div className="testRemaining">
                  <p>
                    Test {testIndex + 1} of {tests.length}
                  </p>
                </div>

                <div className="questionsDiv">
                  <div className="questionNumber">
                    <p>
                      Question {questionIndex + 1} of{" "}
                      {tests[testIndex]?.question.length}
                    </p>
                  </div>
                  <div className="question">
                    {ReactHtmlParser(
                      tests[testIndex]?.question[questionIndex]?.question
                    )}
                  </div>
                </div>
                <h6>Select the right option</h6>
                <div>
                  {/* <McqsOptions /> */}
                  {tests[testIndex]?.question[questionIndex]?.mcq_options.map(
                    (item, index) => {
                      return (
                        <>
                          <div className="optionsDiv">
                            <label>
                              <div className="selector">
                                <input
                                  required={!selectedOption}
                                  type="radio"
                                  id="option1"
                                  checked={
                                    item?.key === selectedOption ? true : false
                                  }
                                  name="rightOption"
                                  key={item.key}
                                  onClick={() => {
                                    setIndexOfChoices(item?.key);
                                  }}
                                />
                                <div className="mcqs">
                                  <p>{item.option}</p>
                                </div>
                              </div>
                            </label>
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
                <div className="btnNext">
                  <Button type="submit" onClick={handleIndex} dark={"dark"}>
                    Next
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default FirstAssessment;
