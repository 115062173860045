import React, { useState, useEffect } from "react";
import "./styles.scss";

import Button from "../../Common/Button";
import HorizontalLine from "../../Common/HorizontalLine";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import {
  darkLogo,
  moreMenu,
  darkMenu,
  whiteSmallLogo,
} from "../../../assets/icons";

const Navbar = ({ light = false }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeScreen, setActiveScreen] = useState(window.location.pathname);

  const Organizaiton = useSelector((state) => state.auth.organizaiton);

  const navigate = useNavigate();

  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    return () => {
      // destructor
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <section
        className={`dark ${light === true ? "light" : ""} 
        `}
      >
        <div className="container">
          <nav>
            <div onClick={() => navigate(`/`)} className="logoDiv">
              {!light && (
                <img src={whiteSmallLogo} alt="" width={95} height={35} />
              )}
              {light && <img src={darkLogo} alt="" width={95} height={35} />}
            </div>

            <ul>
              {/* <li
                className={`${activeScreen === "/" ? "active" : ""}`}
                onClick={() => {
                  navigate(`/`);
                }}
              >
                Home
              </li> */}
              {/* <li
                className={`${activeScreen === "/product" ? "active" : ""}`}
                onClick={() => {
                  navigate(`/product`);
                }}
              >
                Product
              </li>
              <li
                className={`${
                  activeScreen === "/test-library" ? "active" : ""
                }`}
                onClick={() => {
                  navigate(`/test-library`);
                }}
              >
                Test&nbsp;Library
              </li>
              <li
                className={`${activeScreen === "/pricing" ? "active" : ""}`}
                onClick={() => {
                  navigate(`/pricing`);
                }}
              >
                Pricing
              </li> */}
            </ul>

            <div className="btnDiv">
              <div>
                <div>
                  <img
                    onClick={openModal}
                    className="moreMenu"
                    src={moreMenu}
                    alt=""
                    width={27}
                    height={19}
                  />
                </div>
              </div>
              <div className="darkMenuDiv">
                <img
                  className="DarkMenu"
                  onClick={openModal}
                  src={darkMenu}
                  alt=""
                />
              </div>
              {!Organizaiton && (
                <div className="btnDark">
                  {/* <Button
                    outline
                    onClick={() => {
                      navigate(`/signup`);
                    }}
                  >
                    <p className="signUpBtnText">Sign&nbsp;up</p>
                  </Button> */}

                  <Button
                    onClick={() => {
                      navigate(`/waitList`);
                    }}
                  >
                    <p className="loginBtnText">Join Waitlist</p>
                  </Button>
                  {/* <Button
                    onClick={() => {
                      navigate(`/loginScreen`);
                    }}
                  >
                    <p className="loginBtnText">Login</p>
                  </Button> */}
                </div>
              )}

              {/* {Organizaiton && (
                <div className="btnDark">
                  <Button
                    onClick={() => {
                      navigate(`/create-assessments`);
                    }}
                  >
                    Go&nbsp;to&nbsp;dashboard
                  </Button>
                </div>
              )} */}
              {/* {!Organizaiton && (
                <div className="btnLight">
                  <Button
                    Darkoutline
                    onClick={() => {
                      navigate(`/signup`);
                    }}
                  >
                    Sign&nbsp;up
                  </Button>

                  <Button
                    dark
                    onClick={() => {
                      navigate(`/loginScreen`);
                    }}
                  >
                    Login
                  </Button>
                </div>
              )}
              {Organizaiton && (
                <div className="btnLight">
                  <Button
                    dark
                    onClick={() => {
                      navigate(`/create-assessments`);
                    }}
                  >
                    Go&nbsp;to&nbsp;dashboard
                  </Button>
                </div>
              )} */}
            </div>
          </nav>
        </div>
        <div className="whiteLine">
          <HorizontalLine white />
        </div>
        <div className="darkLine">
          <HorizontalLine black />
        </div>
      </section>

      {/* -------------------------- Modal -------------------------- */}

      <Modal
        onRequestClose={closeModal}
        isOpen={modalIsOpen}
        className="Modal"
        style={{
          overlay: {
            backgroundColor: "none",
            zIndex: 10000,
          },
          content: {
            backgroundColor: " rgba(255, 255, 255,0.75)",
            // backdropFilter: "blur(75px)",
            outline: "none",
            position: "absolute",
            top: "12rem",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: "500",
            width: "90%",
            fontFamily: "Lato",
          },
        }}
      >
        <div className="mobNav">
          <ul>
            {/* <li
              className={`${activeScreen === "/" ? "active" : ""}`}
              onClick={() => {
                navigate(`/`);
              }}
            >
              Home
            </li>
            <li
              className={`${activeScreen === "/product" ? "active" : ""}`}
              onClick={() => {
                navigate(`/product`);
              }}
            >
              Product
            </li>
            <li
              className={`${activeScreen === "/test-library" ? "active" : ""}`}
              onClick={() => {
                navigate(`/test-library`);
              }}
            >
              Test&nbsp;Library
            </li>
            <li
              className={`${activeScreen === "/pricing" ? "active" : ""}`}
              onClick={() => {
                navigate(`/pricing`);
              }}
            >
              Pricing
            </li> */}
          </ul>
          {!Organizaiton && (
            <div className="btnModal">
              {/* <Button
                Darkoutline
                onClick={() => {
                  navigate(`/signup`);
                }}
              >
                Sign up
              </Button> */}

              <div className="mobLogin">
                <Button
                  dark
                  onClick={() => {
                    navigate(`/waitList`);
                  }}
                >
                  Join WaitList
                </Button>
              </div>
            </div>
          )}
          {/* {Organizaiton && (
            <div className="btnModal">
              <Button
                dark
                onClick={() => {
                  navigate(`/create-assessments`);
                }}
              >
                Go&nbsp;to&nbsp;dashboard
              </Button>
            </div>
          )} */}
        </div>
      </Modal>
    </>
  );
};
export default Navbar;
