import React from "react";
import "./styles.scss";
import Button from "../../Common/Button";
import { useNavigate } from "react-router-dom";
import { editMockup } from "../../../assets/images";

const Quality = () => {
  const navigate = useNavigate();
  return (
    <section className=" quality">
      <div className="quality-heading">
        <div className="mainText">
          <h2>Offer a good quality experience to candidates.</h2>
          <h5>
            Our professional, well-designed tests serve as an extension of your
            brand
            <br />
            and personality.Your applicants get the motivation and engagement
            they
            <br />
            need to truly shine.
          </h5>
        </div>
        <div className="mobText">
          <h2>
            Offer a good quality
            <br /> experience to candidates.
          </h2>

          <h5>
            Our professional, well-designed tests serve as an
            <br /> extension of your brand and personality.Your
            <br />
            applicants get the motivation and engagement
            <br />
            they need to truly shine.
          </h5>

          <div

            className="btnStarted"
          >
            <Button onClick={() => {
              navigate(`/form`);
            }}>Get started</Button>
          </div>
        </div>
      </div>
      <img src={editMockup} alt="" />
    </section>
  );
};
export default Quality;
