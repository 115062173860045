import React from "react";
import Button from "../../Common/Button";
import Card from "../Card";
import "./styles.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { slider } from "../../../assets/icons/index";
import {
  verticalCurveLine,
  threeVerticalLines,
  curve,
} from "../../../assets/icons";
import {
  girlImage,
  guyInBlue,
  guyInRed,
  guyInBlack,
} from "../../../assets/images";

const Header = () => {
  const navigate = useNavigate();
  return (
    <section className="Header">
      <div className="container">
        <div className="headerDiv">
          <div className="headerLeft">
            <div className="headerLeft-container">
              <h1>
                Next generations
                <br />
                hiring solution
                <span className="verticalLines">
                  <img src={threeVerticalLines} alt="" />
                </span>
                <span className="curveLine">
                  <img src={verticalCurveLine} alt="" />
                </span>
                <span className="curve">
                  <img src={curve} alt="" />
                </span>
              </h1>
              <p className="description">
                Our screening tests identify the best candidates and make your
                hiring decisions faster, easier, and bias-free.
              </p>
              <div className="getStartButton">
                <Button
                  onClick={() => {
                    navigate(`/signup`);
                  }}
                >
                  Get&nbsp;Started
                </Button>
              </div>
            </div>
          </div>
          <div className="headerRight">
            <div className="upperProfile">
              <Card height={257} width={252} image={girlImage} />
              <Card height={257} width={252} image={guyInBlue} />
            </div>
            <div className="lowerProfile">
              <Card height={257} width={252} image={guyInBlue} />
              <Card height={257} width={252} image={guyInRed} />
              <Card height={257} width={252} image={guyInBlack} />
            </div>

            <div>
              <div className="forTablet">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  width={"50%"}
                  transitionTime={"1s"}
                  showArrows={false}
                  showIndicators={false}
                  showThumbs={false}
                  showStatus={false}
                  centerSlidePercentage={33}
                  centerMode={true}
                  selectedItem={2}
                >
                  <Card height={234} width={238} image={guyInBlack} />
                  <Card height={234} width={238} image={guyInBlue} />

                  <Card height={234} width={238} image={girlImage} />
                  <Card height={234} width={238} image={guyInRed} />
                  <Card height={234} width={238} image={guyInBlue} />
                </Carousel>
              </div>

              <div className="forMob">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  width={"23%"}
                  transitionTime={"1s"}
                  showArrows={false}
                  showIndicators={false}
                  showThumbs={false}
                  showStatus={false}
                  centerSlidePercentage={100}
                  centerMode={true}
                  selectedItem={1}
                >
                  <Card height={234} width={238} image={guyInBlue} />
                  <Card height={234} width={238} image={girlImage} />
                  <Card height={234} width={238} image={guyInRed} />
                </Carousel>
              </div>
            </div>
          </div>
          <img className="slider" src={slider} alt="" width={51} />
        </div>
      </div>
      <div className="firstCircle"></div>
      <div className="secondCircle"></div>
    </section>
  );
};

export default Header;
