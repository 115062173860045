import styled from "styled-components";
export const Wrapper = styled.div`
  button {
    width: 151px;
    height: 45px;
    border:2px solid white;
    background: ${(props) =>
      props.theme === "primary"
        ? "#ffffff"
        : props.theme === "secondary"
        ? "transparent"
        : ""};
    border-radius: 45px;
    cursor: pointer;
    color: ${(props) =>
      props.theme === "primary"
        ? "black"
        : props.theme === "secondary"
        ? "white"
        : ""};
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 115%;
    text-align: center;
    letter-spacing: 0.03em;
    padding:0;
  }
`;
