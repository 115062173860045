import React from "react";
import Button from "../../../components/Common/Button";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import { whiteLargeLogo } from "../../../assets/icons";

const CandidateGetStarted = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  return (
    <div className="CandidateGetStarted">
      <div className="container">
        <div className="remember">
          <p>
            <span>Welcome to Hiring Hash</span>
            <br />
            <br />
            We are providing testing services for candidate selection for
            companies for their smooth and efficient hiring of credible
            resources. This helps the company to identify the resources better
            fit for their position. Hiring Hash also allows the candidate to get
            ready for their job interviews and mock test for a better outcome
            for job hunting.
          </p>
          <h5>Instructions for a successful assessment</h5>
          <ul>
            <li>
              <span>Time Limit:</span> Each section comes with a designated time
              limit. Use it wisely to provide your best responses.
            </li>
            <li>
              <span>Ethics Matter:</span>Maintain integrity throughout the
              assessment. Avoid external aids or assistance.
            </li>
            <li>
              <span>Section Overview:</span>Understand the skills assessed in
              each section before diving in.
            </li>
            <li>
              <span>Technical Requirements:</span>
              Ensure a stable internet connection and an up-to-date browser for
              a seamless experience.
            </li>
            <li>
              <span>Best of Luck:</span>
              You've got this! Click "Get Started" to showcase your skills
              confidently
            </li>
          </ul>
          <div className="btnStarted">
            <Button
              large
              onClick={() => {
                navigate(`/AssessmentOverview/${token}`);
              }}
            >
              Get&nbsp;Started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateGetStarted;
