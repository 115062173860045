import React from "react";
import "./styles.scss";

const Star = ({onClick , fill}) => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill={fill?"transparent":"rgba(253, 173, 0, 1)"}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="star"
    >
      <path
        d="M27.4895 13.9086L27.9152 14.8507L28.9457 14.9326L40.9244 15.8843C40.9246 15.8843 40.9248 15.8843 40.9249 15.8843C40.9936 15.8899 41.0591 15.9155 41.1135 15.9578C41.1676 15.9999 41.2082 16.0569 41.2305 16.1216C41.2755 16.2567 41.2368 16.4031 41.1321 16.498C41.1319 16.4981 41.1317 16.4983 41.1315 16.4985L31.6016 25.0733L30.7892 25.8043L31.0895 26.8552L34.9314 40.2966C34.9316 40.2972 34.9317 40.2977 34.9319 40.2983C34.951 40.3672 34.9487 40.4402 34.9253 40.5078C34.9018 40.576 34.8579 40.6353 34.7996 40.6778C34.7412 40.7203 34.6712 40.744 34.599 40.7456C34.5272 40.7472 34.4566 40.7268 34.3966 40.6873C34.3963 40.6871 34.396 40.6869 34.3957 40.6867L22.9567 33.0617L21.986 32.4147L21.0154 33.0617L9.57364 40.6885L9.57172 40.6898C9.51317 40.7289 9.44417 40.7496 9.37368 40.749C9.3032 40.7485 9.23452 40.7268 9.17658 40.6868C9.11862 40.6467 9.07408 40.5902 9.04868 40.5246C9.02329 40.459 9.01821 40.3873 9.03408 40.3188L9.03435 40.3177L12.1653 26.7617L12.387 25.8022L11.6817 25.1147L2.8288 16.4858L2.82868 16.4856C2.78035 16.4385 2.74665 16.3786 2.73156 16.3129C2.71647 16.2472 2.72063 16.1785 2.74354 16.1151C2.76646 16.0517 2.80717 15.9962 2.86084 15.9553C2.91452 15.9144 2.97887 15.8897 3.04622 15.8844L3.04664 15.8844L15.0263 14.9326L16.0569 14.8507L16.4826 13.9085L21.6666 2.43264L21.6687 2.42777C21.6961 2.3667 21.7406 2.31481 21.7968 2.2784C21.8531 2.24199 21.9187 2.22262 21.9858 2.22266C22.0528 2.22269 22.1184 2.24213 22.1746 2.27861C22.2308 2.31508 22.2752 2.36702 22.3025 2.42812L22.3025 2.42812L22.3056 2.43484L27.4895 13.9086Z"
        stroke="#FDAD00"
        stroke-width="3.5"
      />
    </svg>
  );
};

export default Star;
