import axios from "axios";
import webURL from "../screens/Utils/Api";

export const Candidates = {
  InviteCandidate: async (token, id, values) => {
    return axios.post(
      `${webURL}/candidate/invitation/${id}`,
      {
        first_name: values.name,
        last_name: values.lastName,
        email: values.email,
      },
      {
        headers: { Authorization: ` Bearer ${token}` },
      }
    );
  },

  AddReview: async (token, rating, comment) => {
    return axios.post(`${webURL}/add-review/${token}`, {
      ratings: rating + 1,
      comment: comment,
    });
  },

  CandidateVerification: async (values, token) => {
    return axios.post(`${webURL}/candidate-verification/${token}`, values, {
      headers: { Authorization: ` Bearer ${token}` },
    });
  },

  CandidatesById: async (id, token, assessmentID) => {
    return await axios.get(`${webURL}/candidate-stats/${id}/${assessmentID}`, {
      headers: { Authorization: ` Bearer ${token}` },
    });
  },

  AllCandidate: async (page, limit, token, candidateName) => {
    return axios.get(
      `${webURL}/organizationCandidates?page=${page}&limit=${limit}&name=${candidateName}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  allCandidateStats: async (token) => {
    return axios.get(`${webURL}/organizationCandidates`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  UpdateCandidateStatus: async (token, value, id) => {
    return axios.put(
      `${webURL}/candidate/update/${id}`,
      {
        status: value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },

  CandidateResult: async (token, id) => {
    return await axios.get(`${webURL}/candidate-stats/${id}`, {
      headers: { Authorization: ` Bearer ${token}` },
    });
  },
};
