import React, { useState, useEffect } from "react";
import "./styles.scss";
import { exit } from "../../../assets/icons";
import Modal from "react-modal";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SignUp, Organizaiton } from "../../../state/authSlice";
import Spinner from "../Spinner";

const LogoutModal = ({ setLogoutModal, logoutModal }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    setSpinner(true);
    dispatch(SignUp(null));
    dispatch(Organizaiton(false));
    window.localStorage.setItem("token", null);
    navigate(`/`);
  };

  useEffect(() => {
    if (logoutModal === true) {
      openModal();
    }
  }, [logoutModal]);

  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsOpen(false);
    setLogoutModal(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    return () => {
      // destructor
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalIsOpen}
      className="Modal"
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.4)",
          zIndex: 10000,
        },
        content: {
          backgroundColor: " rgba(255, 255, 255)",
          // backdropFilter: "blur(75px)",
          outline: "none",
          position: "absolute",
          top: "30rem",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          zIndex: "500",
          width: "600px",
          fontFamily: "Lato",
          borderRadius: "10px",
        },
      }}
    >
      <div className="logout">
        <h1>Are you sure you want to Logout ?</h1>
        <div className="btnLogout">
          <Button onClick={handleLogout} large>
            {spinner ? <Spinner /> : "Logout"}
          </Button>
        </div>
        <img onClick={closeModal} className="exit" src={exit} alt="" />
      </div>
    </Modal>
  );
};

export default LogoutModal;
