import React from "react";
import "./styles.scss";
import Button from "../../Common/Button";
import { useNavigate } from "react-router-dom";
import { peopleGroup } from "../../../assets/images";

const Recruitment = () => {
  const navigate = useNavigate();
  return (
    <section className="recruitment">
      <div className="container">
        <div className="mainText">
          <h2>Take recruitment to the next level...</h2>
        </div>
        <div className="mobText">
          <h2>
            Take recruitment to the <br /> next level...
          </h2>
        </div>
        <div className="main">
          <div>
            <h5 className="mainContent">
              We rank your applicants according to test scores certified by our
              <br />
              experts. This means your HR team can rely on good data
              <br />
              to make important decisions – and not just their gut feeling.
            </h5>

            <h5 className="mainContent">
              With Hiring Hash, you’ll lead the shift to a more professional
              <br />
              and data-focused HR culture in your company.
            </h5>
            <div className="btnStarted">
              <Button
                // onClick={() => {
                //   navigate(`/signup`);
                // }}
                dark
              >
                Get&nbsp;started
              </Button>
            </div>
          </div>
          <div>
            <img
              className="peopleGroup"
              src={peopleGroup}
              alt=""
              width={672}
              height={560}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Recruitment;
