import React from "react";
import { Route, useLocation, Navigate, useParams } from "react-router-dom";

const AssessProjection = ({ children }) => {
  const location = useLocation();
  const routeName = location.pathname.split("/");
  const { token } = useParams();
  const Assess = JSON.parse(localStorage.getItem("Assessment"));
  console.log(Assess, "This is asess");
  return (
    <>
      {Assess !== 1 ? (
        children
      ) : Assess === 1 ? (
        <Navigate to={`/FirstAssessment/${token}`} />
      ) : (
        <Navigate to={`/ThankyouNote/${token}`} />
      )}
    </>
  );
};

export default AssessProjection;
