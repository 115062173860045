import axios from "axios";
import webURL from "../screens/Utils/Api";

export const Auth = {
  Register: async (values) => {
    return axios.post(`${webURL}/register`, values);
  },
  Login: async (values) => {
    return axios.post(`${webURL}/login`, values);
  },
  Profile: async (token) => {
    return axios.get(`${webURL}/profile`,{
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  Organization: async (token, formData) => {
    return axios.post(`${webURL}/organization`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  GoogleLogin: async (token) => {
    return axios.post(`${webURL}/googleLogin`, {
      googleAccessToken: token,
    });
  },
};