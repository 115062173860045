import React from "react";
import "./styles.scss";

const InputField = ({
  children,
  label,
  type,
  name,
  id,
  icon,
  title,
  readonly,
  required,
  value,
  defaultValue,
  onIconClick,
  ...props
}) => {
  const classProps = ["light", "blank", "dull", "white", "dotted", "error"];
  const classNames = classProps.filter((propName) => props[propName]).join(" ");

  return (
    <>
      {label && <label>{label}</label>}
      <div className="forIcon">
        <input
          {...props}
          className={classNames}
          type={type}
          name={name}
          id={id}
          placeholder={children}
          aria-label="Search"
          readOnly={readonly}
          required={required}
          value={value}
          defaultValue={defaultValue}
        />
        {icon && (
          <img
            className="inputfieldIcon"
            src={icon}
            alt=""
            width={18}
            onClick={onIconClick}
          />
        )}
      </div>
    </>
  );
};

export default InputField;
