import React from "react";
import Button from "../../components/Common/Button";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { Profile } from "../../assets/icons/index";

export default function Congratulation() {
  const navigate = useNavigate();

  return (
    <div className=" Congratulation">
      <div className="container innerDiv">
        <h1>Congratulations...</h1>
        <div className="profileDiv">
          <img src={Profile} alt="" width={200} height={50} />
          <p> &amp; 100+ others are on waitlist...</p>
        </div>
        <h5>
          HiringHash is the #1 rated technical interview platform for teams to
          test the
          <br />
          coding skills of developers and make evidence-based hiring decisions.
        </h5>

        <Button
          large
          onClick={() => {
            navigate(`/`);
          }}
        >
          Done
        </Button>
      </div>
      <div className="Circle"></div>
      <div className="CircleTwo"></div>
    </div>
  );
}
