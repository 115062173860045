import React, { useEffect } from "react";
import "./styles.scss";
import InputField from "../../components/Common/InputField";
import Button from "../../components/Common/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useFormik, Form, Formik } from "formik";
import Spinner from "../../components/Common/Spinner";
import DropdownSelect from "../../components/Common/DropdownSelect/DropdownSelect";
import { waiting } from "../../assets/images";
import { darkLogo } from "../../assets/icons";
import { Waitlist } from "../../service/Waitlist";

const WaitList = () => {
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [profession, setProfession] = useState("");

  const [spinner, setSpinner] = useState(false);
  const [professionError, setProfessionError] = useState(false);

  useEffect(() => {
    setProfessionError(false);
  }, [profession]);

  const checkError = () => {
    if (profession === "") {
      setProfessionError(true);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    } else if (values.name.length > 15) {
      errors.name = "Must be 15 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validate,
    onSubmit: (values) => {
      joinWaitlist(values);
    },
  });

  const joinWaitlist = async (values) => {
    values["companyName"] = companyName;
    values["profession"] = profession;
    setSpinner(true);
    Waitlist.Presale(values)
      .then((resp) => {
        setSpinner(false);
        navigate(`/congratulation`);
      })
      .catch((err) => {
        setSpinner(false);
        console.warn(err);
      });
  };

  return (
    <div className="waitList">
      <div className="container mainDiv">
        <div className="wait">
          <img src={waiting} alt="" width={570} height={430} />
        </div>
        <div className="form">
          <div className="mainDiv">
            <div className="darkLogo">
              <div>
                <img
                  onClick={() => {
                    navigate(`/`);
                  }}
                  src={darkLogo}
                  alt=""
                  width={140}
                  height={55}
                />
              </div>
            </div>
            <h6>Tell us more about your organization</h6>
            <Formik>
              <Form onSubmit={formik.handleSubmit}>
                <div className="textfield">
                  <InputField
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    blank
                    label={"Full Name"}
                    id="name"
                    name="name"
                    type="text"
                    error={formik.errors.name && formik.touched.name}
                    onBlur={formik.handleBlur}
                  ></InputField>
                  {formik.errors.name && formik.touched.name ? (
                    <div className="forErrors">{formik.errors.name}</div>
                  ) : null}
                </div>
                <div className="textfield">
                  <InputField
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    blank
                    label={"Email Address"}
                    id="email"
                    name="email"
                    type="email"
                    error={formik.errors.email && formik.touched.email}
                    onBlur={formik.handleBlur}
                  ></InputField>
                  {formik.errors.email && formik.touched.email ? (
                    <div className="forErrors">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="textfield">
                  <InputField
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    blank
                    label={"Company Name (Optional)"}
                  ></InputField>
                </div>
                <div className="textfield">
                  <h4>Profession</h4>
                  <DropdownSelect
                    dropdownOptions={[
                      "Owner",
                      "Admin",
                      "HR",
                      "Employee",
                      "Others",
                    ]}
                    selected={profession}
                    setSelected={setProfession}
                    error={professionError}
                  />
                </div>
                <div className="btnNext">
                  <Button
                    type="submit"
                    onClick={checkError}
                    large
                    disable={spinner}
                  >
                    {spinner ? <Spinner /> : "Join waitlist"}
                  </Button>
                </div>
              </Form>
            </Formik>
            <img className="backWord" src="./icons/backword.svg" alt="" />
          </div>
        </div>
      </div>
      {/* <div className="Circle"></div>
      <div className="CircleTwo"></div> */}
    </div>
  );
};
export default WaitList;
