import React from "react";
import TopTalent from "../TopTalent";
import "./styles.scss";
import {
  circleOne,
  circleTwo,
  circleThree,
  circleFour,
} from "../../../assets/icons";

const Talent = () => {
  return (
    <section className=" talent">
      <div className="container">
        <div>
          <span className="talent-section-heading">
            <p>Top talent is priceless.</p>
            <p>we make finding it affordable.</p>
          </span>
          <div className="text">
            <p>
              Our screening tests identify the best candidates and
              <br />
              make your hiring decisions faster, easier, and bias-free.
            </p>
          </div>
        </div>
        {/* <div className="talentDiv">
          <div className="upperDiv">
            <TopTalent image={circleOne} />
            <div className="rightBlock">
              <TopTalent color={"#fdf5d1"} image={circleTwo} />
            </div>
          </div>
          <div className="lowerDiv">
            <TopTalent color={"#fdf5d1"} image={circleThree} />
            <div className="rightBlock">
              <TopTalent image={circleFour} />
            </div>
          </div>
        </div> */}

        <div className="top-talent-cards-wrapper">
          <TopTalent image={circleOne} />
          <TopTalent color={"#fdf5d1"} image={circleTwo} />
          <TopTalent image={circleThree} />
          <TopTalent color={"#fdf5d1"} image={circleFour} />
        </div>
      </div>
    </section>
  );
};

export default Talent;
