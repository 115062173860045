import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  // For useSelector
  initialState: {
    token: null,
    organizaiton: false,
    ID: null,
    setScreen: "assessment",
    isEmailVerified: false,
    userId: null,
  },
  // For useDispatch
  reducers: {
    SignUp: (state, action) => {
      state.token = action.payload;
    },
    Organizaiton: (state, action) => {
      state.organizaiton = action.payload;
    },
    assessmentID: (state, action) => {
      state.ID = action.payload;
    },
    Screen: (state, action) => {
      state.setScreen = action.payload;
    },

    EmailVerify: (state, action) => {
      state.isEmailVerified = action.payload;
    },

    IdVerify: (state, action) => {
      state.userId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  SignUp,
  Organizaiton,
  assessmentID,
  Screen,
  EmailVerify,
  IdVerify,
} = authSlice.actions;

export default authSlice.reducer;
