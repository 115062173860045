import React from "react";
import "./styles.scss";
import Button from "../../Common/Button";
import Logos from "../Logos";
import { useNavigate } from "react-router-dom";
import {
  react,
  flutter,
  bry,
  node,
  cube,
  azure,
  laravel,
  docker,
  atlassian,
  tf,
  twoCurves,
} from "../../../assets/icons";

const Test = () => {
  const navigate = useNavigate();

  const componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="test">
      <div className="container">
        <div>
          <div className="tabHeading">
            <h2>
              We test programming languages, frameworks,
              <br />
              and libraries
            </h2>
          </div>
          <div className="mobHeading">
            <h2>
              We test programming languages,
              <br /> frameworks, and libraries
            </h2>
          </div>
          <div className="tabText">
            <p>
              Our screening tests identify the best candidates and <br />
              make your hiring decisions faster, easier, and bias-free.
            </p>
          </div>
          <div className="mobText">
            <p>
              Test candidates for job-specifically skills like <br />
              coding or digital marketing, as well as more <br />
              general skills like critical thinking.
            </p>
          </div>
        </div>
        <div className="btnStart">
          <Button
          // onClick={() => {
          //   navigate(`/form`);
          // }}
          >
            Get started
          </Button>
        </div>
        <div className="testMain">
          <div className="btnDiv">
            <h5 className="testContent">
              Predict real-world job performance using our library of 211
              <br />
              scientifically validated tests.
            </h5>

            <h5 className="testContent">
              Test candidates for job-specific skills like coding or digital
              marketing,
              <br />
              as well as more general skills like critical thinking.
            </h5>

            <h5 className="testContent">
              Our unique personality and value tests allow you to get to know
              your
              <br />
              applicants as real people – not just pieces of paper.
            </h5>
            <div className="btnTest">
              <Button
                // onClick={() => {
                //   componentDidMount();
                //   navigate(`/test-library`);
                // }}
              >
                Test&nbsp;library
              </Button>
            </div>
          </div>
          <div>
            <div className="logosDiv">
              <Logos className="react" image={react} width={103} height={92} />
              <Logos
                className="flutter"
                image={flutter}
                width={82}
                height={83}
              />
              <Logos className="bry" image={bry} width={85} height={73} />
              <div className="mobResponsive">
                <Logos image={node} width={122} height={32} />
              </div>
            </div>

            <div className="logosDiv">
              <Logos className="cube" image={cube} width={81} height={80} />
              <Logos className="azure" image={azure} width={84} height={96} />
              <Logos
                className="laravel"
                image={laravel}
                width={79}
                height={86}
              />
              <div className="mobResponsive">
                <Logos image={docker} width={91} height={63} />
              </div>
            </div>

            <div className="logosDiv">
              <Logos
                className="twoCurves"
                image={twoCurves}
                width={92}
                height={69}
              />
              <Logos className="tf" image={tf} width={80} height={81} />
              <div className="mobResponsive">
                <Logos image={atlassian} width={73} height={73} />
              </div>

              <Logos>
                &amp;
                <br />
                many
                <br />
                others
                <br />
                ...
              </Logos>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Test;
