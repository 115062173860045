import React, { useState } from "react";
import "./styles.scss";
import InputField from "../../../components/Common/InputField";
import Button from "../../../components/Common/Button";
import Checkbox from "rc-checkbox";
import { useNavigate, useParams } from "react-router-dom";
import { hiringHash } from "../../../assets/icons";
import { serviceErrorHandler } from "../../../config";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Common/Spinner";
import { useDispatch } from "react-redux";
import { assessmentID } from "../../../state/authSlice";
import { Formik } from "formik";
import * as yup from "yup";
import useToken from "../../../hooks/useToken";
import { Candidates } from "../../../service/Candidate";

const candidateInformation = [
  {
    label: "First Name",
    valueKey: "first_name",
  },
  {
    label: "Last Name",
    valueKey: "last_name",
  },
  {
    label: "Contact No.",
    valueKey: "phoneNumber",
  },
  {
    label: "Semester",
    valueKey: "semester",
  },
  {
    label: "University Name",
    valueKey: "universityName",
  },
  {
    label: "Industry Experience",
    valueKey: "industryExperience",
  },
  {
    label: "Current Employement Status",
    valueKey: "currentEmplymentStatus",
  },
  {
    label: "Job Position",
    valueKey: "jobPosition",
  },
];

const CandidateVerification = () => {
  const [spinner, setSpinner] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bearerToken = useToken();

  const handleCheckBox = () => {
    setCheckbox(!checkbox);
  };

  async function candidateVerification(values) {
    if (!checkbox) {
      toast.error("You need to accept the terms and conditions");
      return;
    }
    setSpinner(true);
    Candidates.CandidateVerification(values, token)
      .then((resp) => {
        setSpinner(false);
        dispatch(assessmentID(resp.data.assessment._id));
        localStorage.setItem("candidateInfo", values.first_name);
        navigate(`/CandidateGetStarted/${token}`);
      })
      .catch((error) => {
        console.warn(error);
        setSpinner(false);
        toast.error(serviceErrorHandler(error));
      });
  }

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        phoneNumber: "",
        semester: "",
        universityName: "",
        industryExperience: "",
        currentEmplymentStatus: "",
        jobPosition: "",
      }}
      onSubmit={async (values) => {
        candidateVerification(values);
      }}
      validationSchema={yup.object().shape({
        first_name: yup.string().required("Required"),
        last_name: yup.string().required("Required"),
        phoneNumber: yup.string().required("Required"),
        semester: yup.string().required("Required"),
        universityName: yup.string().required("Required"),
        industryExperience: yup.string().required("Required"),
        currentEmplymentStatus: yup.string().required("Required"),
        jobPosition: yup.string().required("Required"),
      })}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        handleBlur,
      }) => {
        return (
          <div className="CandidateDesktop">
            <div className="formDiv">
              <img src={hiringHash} alt="" width={204} height={204} />
              <h6>Please fill in your information</h6>
              {candidateInformation.map((field, index) => {
                return (
                  <div className="inputDiv">
                    <InputField
                      label={field.label}
                      white
                      value={values[field.valueKey]}
                      onChange={handleChange(field.valueKey)}
                      error={errors[field.valueKey] && touched[field.valueKey]}
                      errorText={errors[field.valueKey]}
                      onBlur={handleBlur(field.valueKey)}
                    ></InputField>
                    <div className="forErrors">
                      {touched[field.valueKey] && errors[field.valueKey]}
                    </div>
                  </div>
                );
              })}
              <div className="checkBoxDiv">
                <input
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                  onChange={() => {
                    handleCheckBox();
                    handleChange("terms");
                  }}
                  className="checkBox"
                />
                <p>
                  By clicking Create free account, you agree to the HiringHash
                  Terms of Service and Privacy Policy.
                </p>
              </div>
              <Button onClick={handleSubmit} type="submit" large>
                {spinner ? <Spinner /> : "Next"}
              </Button>
            </div>
            <ToastContainer />
          </div>
        );
      }}
    </Formik>
  );
};

export default CandidateVerification;
