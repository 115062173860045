import React from "react";
import "./styles.scss";

const HorizontalLine = ({
  white = false,
  black = false,
  smallBlack = false,
  smallWhite = false,
  dashed = false,
  Blight = false,
  noShadow = false,
  progress = false,
  currentActive = false,
  vertical = false,
}) => {
  return (
    <div
      className={`
      ${white === true ? "white" : ""} 
      ${black === true ? "black" : ""} 
      ${smallBlack === true ? "smallBlack" : ""}
      ${smallWhite === true ? "smallWhite" : ""}
      ${dashed === true ? "dashed" : ""} 
      ${Blight === true ? "Blight" : ""} 
      ${noShadow === true ? "noShadow" : ""} 
      ${progress === true ? "progress" : ""} 
      ${vertical === true ? "vertical" : ""} 
      ${currentActive === true ? "currentActive" : ""} 
      `}
    ></div>
  );
};
export default HorizontalLine;
