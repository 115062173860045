import React, { useEffect, useState } from "react";
import "./styles.scss";
import InputField from "../../../components/Common/InputField";
import Button from "../../../components/Common/Button";
import ReactSelect from "../../../components/Common/ReactSelect";
import { useFormik, Form, Formik } from "formik";
import Spinner from "../../../components/Common/Spinner";
import { serviceErrorHandler } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useToken from "../../../hooks/useToken";
import { Change } from "../../../service/Change";
import { Auth } from "../../../service/Auth";
import * as Yup from 'yup';

const MyProfile = () => {
  const [role, setRole] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [profile, setProfile] = useState();
  const token = useToken();

  const updateSuccess = (success) => {
    toast(success);
  };

  const authProfile = async () => {
    const res = await Auth.Profile(token);
    setProfile(res?.data.profile);
  };

  useEffect(() => {
    authProfile();
  }, [token]);

  // Define a Yup schema for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Full Name is required')
      .max(15, 'Full Name must be 15 characters or less'),
    role: Yup.string().required('Job Role is required'),
  });

  // Set initial values based on profile data or an empty object if profile is not available
  const initialValues = {
    name: profile?.name || "",
    role: profile?.job_role || "",
  };

  const handleSubmit = (values) => {
    changeRole(values);
  };
  const changeRole = (values) => {
    setSpinner(true);
    values["job_role"] = role;
    Change.ChangeRole(token, values)
      .then((resp) => {
        updateSuccess(resp.data.message);
      })
      .catch((error) => {
        toast.error(serviceErrorHandler(error));
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  return (
    <div className="changeRole">
      {profile ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, errors, touched, handleBlur }) => (
            <Form>
              <div className="inputDiv">
                <InputField
                  blank
                  onChange={handleChange}
                  value={values.name}
                  id="name"
                  name="name"
                  type="text"
                  error={errors.name && touched.name}
                  label={"Full Name"}
                  onBlur={handleBlur}
                ></InputField>
                {errors.name && touched.name ? (
                  <div className="forErrors">{errors.name}</div>
                ) : null}
              </div>
              <div className="jobroleDiv">
                <p>What's your job role?</p>
                <ReactSelect
                  DropdownOptions={[
                    { value: "owner", label: "Owner" },
                    { value: "manager", label: "Manager" },
                    { value: "hr", label: "HR" },
                  ]}
                  setSelected={setRole}
                  onBlur={handleBlur}
                  error={errors.role && touched.role}
                  value={values.role}
                  onChange={handleChange}
                  id={"role"}
                  name={"role"}
                  defaultValue={profile?.job_role}
                />
              </div>
              <div className="btnDiv">
                <Button disable={spinner} type="submit" large>
                  {spinner ? <Spinner /> : "Update"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
      <ToastContainer />
    </div>
  );
};

export default MyProfile;