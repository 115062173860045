import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useToken() {
  const reduxToken = useSelector((state) => state.auth.token);
  const localToken = localStorage.getItem("token");
  const [token, setToken] = useState(null);

  useEffect(() => {
    console.log();
    if (reduxToken !== null) {
      setToken(reduxToken);
    } else {
      setToken(localToken);
    }
  }, [token]);
  return token;
}

export default useToken;
